// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/vsts/work/1/s/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-job-offer-job-offer-tsx": () => import("/home/vsts/work/1/s/src/templates/JobOffer/JobOffer.tsx" /* webpackChunkName: "component---src-templates-job-offer-job-offer-tsx" */),
  "component---src-templates-case-study-design-case-study-hda-design-case-study-hda-tsx": () => import("/home/vsts/work/1/s/src/templates/caseStudy/design-case-study-hda/design-case-study-hda.tsx" /* webpackChunkName: "component---src-templates-case-study-design-case-study-hda-design-case-study-hda-tsx" */),
  "component---src-templates-home-home-tsx": () => import("/home/vsts/work/1/s/src/templates/home/home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-about-us-about-us-tsx": () => import("/home/vsts/work/1/s/src/templates/aboutUs/aboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-tsx" */),
  "component---src-templates-jobs-jobs-tsx": () => import("/home/vsts/work/1/s/src/templates/jobs/jobs.tsx" /* webpackChunkName: "component---src-templates-jobs-jobs-tsx" */),
  "component---src-templates-case-studies-case-studies-tsx": () => import("/home/vsts/work/1/s/src/templates/caseStudies/caseStudies.tsx" /* webpackChunkName: "component---src-templates-case-studies-case-studies-tsx" */),
  "component---src-templates-contact-contact-tsx": () => import("/home/vsts/work/1/s/src/templates/contact/contact.tsx" /* webpackChunkName: "component---src-templates-contact-contact-tsx" */),
  "component---src-templates-imprint-imprint-tsx": () => import("/home/vsts/work/1/s/src/templates/imprint/imprint.tsx" /* webpackChunkName: "component---src-templates-imprint-imprint-tsx" */),
  "component---src-templates-data-protection-data-protection-tsx": () => import("/home/vsts/work/1/s/src/templates/dataProtection/dataProtection.tsx" /* webpackChunkName: "component---src-templates-data-protection-data-protection-tsx" */),
  "component---src-templates-articles-articles-tsx": () => import("/home/vsts/work/1/s/src/templates/articles/articles.tsx" /* webpackChunkName: "component---src-templates-articles-articles-tsx" */),
  "component---src-pages-de-404-tsx": () => import("/home/vsts/work/1/s/src/pages/de/404.tsx" /* webpackChunkName: "component---src-pages-de-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("/home/vsts/work/1/s/src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/vsts/work/1/s/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

