module.exports = [{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WW69Z6J","includeInDevelopment":false,"dataLayerName":"dataLayer"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/vsts/work/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
